@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

form.newsletter-form {
  margin-top: 1rem;
  input {
    padding: 0.75rem 1rem !important;
  }
  button {
    border: 0;
    background-color: #2d506d;
    color: #fff;
    border-radius: 12px;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: bolder;
    width: 100%;
    @include breakpoint($md) {
      padding: 0.5rem 2rem;
      width: max-content;
    }
    .spinner-border {
      height: 1rem;
      width: 1rem;
    }
    &:disabled {
      background-color: #93a3b1 !important;
    }
  }
  .invalid-feedback {
    background-color: lighten(#f6a9b1, 10%);
    border-radius: 10px;
    padding: 0.3rem 0.5rem;
  }
}
