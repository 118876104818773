@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.packages {
  &-modal {
    &__service {
      background-color: #f3f4f5;
      border-radius: 22px;
      padding: 1.5rem 1rem;
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
      @include breakpoint($lg) {
        padding: 1rem;
        grid-template-columns: 70% 1fr;
        gap: 2rem;
      }
      &:not(:first-child) {
        margin-top: 1rem;
      }
      h5 {
        color: $final-blue !important;
        font-size: 1.3rem;
        // margin-bottom: 10px;
        @include breakpoint($lg) {
          margin-bottom: 0;
          font-size: 1.3rem;
        }
      }
      span {
        color: $main-blue;
        font-weight: bolder;
        @include breakpoint($lg) {
          &:hover {
            cursor: pointer;
          }
        }
      }
      &__description {
        margin-bottom: 10px;
        line-height: 1.3;
        @include breakpoint($lg) {
          margin-bottom: 0;
        }
      }
      &__content {
        font-weight: bolder;
        text-align: center !important;
        @include breakpoint($lg) {
          text-align: justify !important;
        }
      }
      &__price {
        font-size: 1.3rem;
        text-align: center !important;
        @include breakpoint($lg) {
          text-align: justify !important;
        }
      }
      p {
        text-align: justify;
      }
      a {
        background-color: $final-blue;
        color: #fff;
        border-radius: 10px;
        padding: 0.65rem 1rem;
        display: block;
        text-align: center;
        text-decoration: none;
        font-weight: bolder;
        transition: all 0.3s ease-in;
        @include breakpoint($lg) {
          &:hover {
            background-color: darken($final-blue, 10%);
          }
        }
      }
    }
    .modal-body {
      padding: 1rem;
      @include breakpoint($lg) {
        padding: 2rem;
      }
    }
  }
}
