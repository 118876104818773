@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.questions {
  // overflow: hidden;
  &__hero {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.73),
        hsla(0, 0%, 98.8%, 0.274)
      ),
      url("../../assets/images/preguntas-frecuentes-hero.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 250px;
    position: relative;
    top: -4rem;
    border-radius: 10px;
    @include breakpoint($lg) {
      border-radius: 0;
      height: 350px;
      width: 104.2%;
      left: -2rem;
      top: -4rem;
    }
  }
  &__content {
    margin-top: -2rem;
    background-color: #fff;
    border-radius: 20px;
    padding: 3rem 1rem;
    h3 {
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .accordion {
      &-button {
        @include breakpoint($md) {
          font-size: 1.2rem;
        }
        &:not(.collapsed) {
          color: #fff;
          background-color: #2d506d;
          &::after {
            background-image: url("../../assets/images/download.svg");
            transform: rotate(-180deg);
          }
        }
      }
      &-body {
        background-color: #efefefa7;
      }
    }
    // p {
    //   text-align: justify;
    //   margin-top: 1rem;
    // }
    // ol {
    //   font-weight: bolder;
    //   padding-left: 1rem;
    //   li {
    //     margin-top: 1rem;
    //   }

    //   p {
    //     font-weight: lighter;
    //     span {
    //       font-weight: bolder;
    //     }
    //   }
    // }
  }
}
