@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.temp {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #fff;
  animation: fadeOut 4s linear forwards;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: 99;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  41% {
    opacity: 1;
  }
  91% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.first-block {
  position: absolute;
  width: 0%;
  height: 110vh;
  background: #cecdca;
  animation: go-left 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  left: 0;
  z-index: 100;
  top: -1.5rem;
}

@keyframes go-left {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

.home {
  &__hero {
    // background-image: url("../../assets/images/hero-desktop-2.png");
    // background-repeat: no-repeat;
    // background-position: center;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2.5rem;
    height: 400px;
    position: relative;
    overflow: hidden;
    @include breakpoint($md) {
      height: 80vh;
      margin-top: -2.5rem;
    }
    .video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      @include breakpoint($md) {
        width: 200%;
      }
    }
    h1 {
      color: #fff;
      font-size: 2.5rem;
      text-transform: uppercase;
      @include breakpoint($md) {
        font-size: 4rem;
      }
    }
    button {
      // background-color: #000;
      color: #fff;
      // text-decoration: none;
      font-family: $main-font;
      display: block;
      width: 90%;
      // border-radius: 15px;
      font-size: 1rem;
      padding: 0.8rem 2rem;
      border: 0;
      // opacity: 0.32;
      // background: rgba(159, 159, 159, 0.509);
      border: 1px solid #fff;
      background-color: transparent;
      border-radius: 9px;
      transition: all 0.2s ease-in;
      position: absolute;
      // bottom: 15%;
      // left: 12%;
      z-index: 100;
      bottom: 5rem;
      &:first-of-type {
        right: 5%;
        bottom: 1rem;
      }
      @include breakpoint($md) {
        margin: 1rem auto 0 auto;
        top: 1%;
        font-size: 1rem;
        padding: 0.4rem 1rem;
        bottom: unset;
        width: max-content;
        &:first-of-type {
          right: 3%;
          bottom: unset;
        }
        right: 15%;
        &:hover {
          // background-color: #fff;
          background: rgba(159, 159, 159, 0.509);
          color: #fff !important;
        }
      }
    }
  }
}

.about {
  // background-color: #f8f8f6;
  // border-radius: 15px;
  margin: 1rem 0;
  padding: 2rem 0 0 0;
  position: relative;
  &::before {
    content: "";
    background-color: #f8f8f6;
    border-radius: 15px;
    position: absolute;
    top: 52%;
    left: 0;
    transform: translateY(-50%);
    height: 550px;
    width: 100%;
    z-index: -1;
    @include breakpoint($md) {
      top: 50%;
      height: 325px;
    }
  }
  @include breakpoint($md) {
    margin: 1rem 0 1.5rem 0;
    padding: 1rem 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    @include breakpoint($md) {
      flex-direction: row;
    }
  }
  &__content,
  &__reviews,
  &__media {
    flex-grow: 1;
    flex-shrink: 1;
  }
  &__content {
    flex-basis: 55%;

    @include breakpoint($md) {
      padding-left: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    h2 {
      line-height: 1.2;
      text-align: center;
      font-family: $secondary-font;
      color: $main-blue;
      font-size: 1.2rem;
      @include breakpoint($md) {
        font-size: 1.3rem;
        text-align: unset;
        line-height: 1.4;
      }
    }
  }
  &__media {
    flex-basis: 45%;
    position: relative;
    img {
      height: 250px;
      display: block;
      margin: 1rem auto 0 auto;
      @include breakpoint($md) {
        margin: 0 auto;
        height: 370px;
        // position: absolute;
        // top: 50%;
        // right: 2rem;
        // transform: translateY(-50%);
      }
    }
  }
  &__reviews {
    flex-basis: 45%;
    .carousel {
      height: 100%;
      &-caption {
        position: unset !important;
        h6,
        p {
          color: #000;
        }
        p {
          color: #000;
          opacity: 50%;
        }
        h6 {
          @include breakpoint($md) {
            max-width: 75%;
            margin: 1rem auto 0 auto;
          }
        }
      }
    }
    &__total {
      display: flex;
      justify-content: center;
      p {
        margin-left: 0.5rem;
        // font-weight: bolder;
      }
    }
  }
}
.services {
  padding: 2rem 0 0 0;
  overflow: hidden;
  @include breakpoint($md) {
    padding: 3rem 0 0 0;
  }
  h3 {
    color: $main-blue;
    text-transform: uppercase;
    color: $main-blue;
  }
  // .container {
  //   height: 70vh; /* Example height */
  //   overflow: hidden; /* Prevent vertical scrolling */
  // }

  .wrapper {
    width: 700%;
    overflow-x: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem 0;
    // justify-content: space-between;
    @include breakpoint($md) {
      margin-top: 0;
      height: 100vh;
      width: 200%;
    }
    section {
      overflow: hidden;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint($md) {
        // height: 100;
        border-top-right-radius: 90px;
        border-top-left-radius: 90px;
        height: 100%;
        // margin-right: 10rem;
      }
    }
  }
  .card {
    border: 0;
    width: 80%;
    height: 100%;
    // overflow: hidden;
    // border-top-left-radius: 150px;
    // border-top-right-radius: 150px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    @include breakpoint($sm-only) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
    }
    // &:nth-child(even) {
    //   margin-bottom: 6rem;
    // }
    &:hover {
      .card__media {
        background-color: #000;
        img {
          opacity: 0.55;
        }
      }
      button {
        // display: block;
        opacity: 1;
        letter-spacing: 1.9px;
      }
    }
    &__media {
      height: 40%;
      // height: 65%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-top-right-radius: 90px;
      border-top-left-radius: 90px;
      overflow: hidden;
      position: relative;
      z-index: 9;
      width: 90%;
      margin: 0 auto;
      @include breakpoint($lg) {
        height: 45%;
        width: 100%;
      }

      img {
        // height: 110%;
        // width: auto;
        height: 100%;
        @include breakpoint($lg) {
        }
      }
      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        transform: translateX(-50%);
        // display: none;
        opacity: 0;
        border: 2px solid #fff;
        font-family: $main-font;
        text-transform: uppercase;
        color: #000;
        background-color: #fff;
        font-size: 1.15rem;
        padding: 0.7rem 1.2rem;
        border-radius: 10px;
        letter-spacing: 1.2px;
        transition: all 0.1s ease-in-out;
        @include breakpoint($lg) {
          padding: 0.9rem 1.6rem;
        }
      }
    }
    &__body {
      height: 50%;
      padding: 1rem 0;
      @include breakpoint($sm-only) {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: 60%;
        height: auto;
        // height: 70%;
      }
      h3 {
        color: #2d506d;
        font-size: 1.2rem;
      }
      p,
      span {
        color: #717171;
        // text-align: justify;
        display: inline-block;
        font-size: 0.9rem;
        line-height: 1.3;
        @include breakpoint($lg) {
        }
      }
      p {
        display: inline;
      }
    }
    &__footer {
      border-top: 2px solid #325978;
      margin-top: 0.6rem;
      padding: 0.6rem 0 0 0;
      display: flex;
      justify-content: space-between;
      img {
        margin-right: 10px;
        height: 18px;
      }
      span,
      p {
        color: $main-blue;
        font-size: 0.9rem;
      }
      p {
      }
    }
  }
  .services-scroll__card {
    // margin-right: 2rem;
    @include breakpoint($md) {
      margin-right: 1rem;
    }
    // margin-top: 1.5rem;
  }
  .first {
    // background-color: lightyellow;
  }
  .second {
    // background-color: lightblue;
  }
  .third {
    // background-color: beige;
  }
  .fourth {
    // background-color: burlywood;
  }
  .fifth {
    // background-color: coral;
  }
  .sixth {
    // background-color: cornflowerblue;
  }
  .all-services {
    margin-top: 1rem;
    text-decoration: underline;
    // opacity: 0.7;
    // font-style: italic;
    font-family: $main-font;
    font-weight: bolder;
    text-transform: uppercase;
    color: $main-blue;
    &:hover {
      cursor: pointer;
    }

    font-size: 1.3rem;
    @include breakpoint($md) {
      font-size: 1.5rem;
    }
  }
  .scrollable-div {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
  }

  .item {
    flex: none;
    width: 400px; /* Example width */
    height: 150px; /* Example height */
    margin-right: 2rem;
    background-color: #ccc;
    /* More styling */
  }
  &__secondary {
    background-color: #f1f0ed;
    border-radius: 40px;
    // display: none;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 2rem 1rem 4rem 1rem;
    margin-top: 2rem;
    @include breakpoint($md) {
      border-radius: 20px;
      display: block;
      // margin-top: -3rem;
      padding: 2rem 0;
    }
    &__links {
      position: relative;
      transform: translate(0px, 0px);
      // @include breakpoint($md) {
      //   margin-top: 1.5rem;
      // }
      &--1 {
        @include breakpoint($md) {
        }
        @include breakpoint($lg) {
          padding-left: 10rem;
        }
      }
      &--2 {
        margin-top: 1.5rem;
        @include breakpoint($md) {
        }
        @include breakpoint($lg) {
          padding-right: 20rem;
        }
      }
      img {
        height: 50px;
        width: auto;
        @include breakpoint($md) {
        }
        @include breakpoint($lg) {
          height: 80px;
        }
        // &:first-of-type {
        //   height: 100px;
        // }
      }
      &--3 {
        margin-top: 1.5rem;
        img {
          height: 45px;
          @include breakpoint($lg) {
            height: 80px;
          }
        }
        @include breakpoint($md) {
        }
        @include breakpoint($lg) {
          padding-left: 25rem;
        }
      }

      &:hover {
        h2 {
          transform: skewX(-15deg);
          transition: all 0.1s ease-in-out;
          z-index: 4;
        }
        .services__secondary__links__image {
          &--1,
          &--2,
          &--3 {
            transform: translate3d(3vw, -50%, 0) rotate(0deg);
            opacity: 1 !important;
          }
          &--2 {
            left: 20%;
            top: 40%;
          }
          &--3 {
            right: -2%;
            @include breakpoint($lg) {
              right: -16%;
            }
          }
        }
      }
      &__image {
        position: absolute;
        top: 50%;
        transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1),
          opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
        opacity: 0;
        pointer-events: none;
        z-index: 2;
        &--1,
        &--2,
        &--3 {
          transform: translate3d(3vw, -50%, 0) rotate(-10deg);
          transform-origin: bottom left;
          img {
            height: 220px;
            width: 220px;
            @include breakpoint($md) {
            }
            @include breakpoint($lg) {
              height: 350px !important;
              width: 350px;
            }
          }
        }
      }
    }

    h2 {
      position: relative;
      width: max-content;
      font-size: 2.3rem;
      font-weight: lighter;
      z-index: 5;
      @include breakpoint($md) {
        font-size: 4.3rem;
        margin-bottom: 0.8rem;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          cursor: pointer;
        }
      }
      // &:nth-child(odd) {
      //   @include breakpoint($md) {
      //     margin-left: -6rem;
      //   }
      // }
      // &:nth-child(even) {
      //   @include breakpoint($md) {
      //     margin-left: 6rem;
      //   }
      // }
    }
    // p {
    //   margin-bottom: 5rem;
    //   @include breakpoint($md) {
    //     margin-bottom: 0;
    //   }
    // }
  }
}
.sticky {
  overflow: hidden;
  position: sticky;
  top: 2rem;
  height: 95vh;
  scroll-behavior: smooth;
  // border: 2px solid red;
  &__parent {
    scroll-behavior: smooth;
    // overflow: hidden;
    height: 500vh;
    @include breakpoint($md) {
      height: 170vh;
    }
  }
}
.scroll_section {
  position: absolute;
  top: 0;
  height: 100%;
  width: 490vw;
  will-change: transform;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scroll-behavior: smooth;
  // padding: 0 2rem;
  transform: translate3d(-3.3vw, 0, 0);
  @include breakpoint($md) {
    height: 100%;
    width: 150vw;
  }
  .card {
    border: 0;
    &:nth-child(even) {
      margin-bottom: 6rem;
    }
    &__media {
    }
    &__body {
      padding: 1rem;
      p {
        color: #717171;
      }
    }
    &__footer {
      border-top: 2px solid #000;
      margin-top: 0.6rem;
      padding: 0.6rem 0 0 0;
      p {
        color: #000;
      }
    }
  }
}
.circuits {
  margin-top: 4rem;
  margin-bottom: 4rem;
  @include breakpoint($md) {
    margin-bottom: 0;
    margin-top: 4rem;
  }
  h3 {
    text-transform: uppercase;
    color: $main-blue;
  }

  &__cards {
    // display: flex;
    // flex-direction: column;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    margin: 2rem 0;
    @include breakpoint($md) {
      // flex-direction: row;
      grid-template-columns: repeat(4, 1fr);

      // justify-content: space-between;
    }
  }
  &__card {
    // border: 1.5px solid #000;
    // border-top-left-radius: 35px;
    // border-top-right-radius: 35px;
    // opacity: 0.46;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // flex-grow: 1;
    height: 550px;
    // flex-shrink: 1;
    background-image: linear-gradient(
      180deg,
      rgba(39, 39, 39, 0.68) 0%,
      rgba(36, 36, 36, 0.66) 64%,
      #c2eeeb 89%,
      #7cfff6 100%
    );
    box-shadow: inset 0 1px 3px 3px $main-blue;
    border-radius: 166px;
    // padding: 1rem;
    overflow: hidden;
    text-align: center;
    color: #fff;
    width: 90%;
    margin: 0 auto;
    @include breakpoint($lg) {
      height: 530px;
      max-width: 85%;
    }
    @include breakpoint($xl) {
      max-width: 80%;
    }
    &:hover {
      & > div {
        &:last-of-type {
          margin-top: 0;
        }
      }
      .overlay {
        opacity: 1;
      }
      h5,
      span {
        display: none;
        // opacity: 0;
      }
    }
    &--0 {
      background-image: url("../../assets/images/circuit-final-1.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    &--1 {
      background-image: url("../../assets/images/detox-dream.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    &--2 {
      background-image: url("../../assets/images/fit-recovery.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    &--3 {
      background-image: url("../../assets/images/hangover-circuit.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    &--4 {
      background-image: url("../../assets/images/hormone-renewal.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    &--5 {
      background-image: url("../../assets/images/just-gave-birth.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    &--6 {
      background-image: url("../../assets/images/relax-unwind.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    &--7 {
      background-image: url("../../assets/images/save-the-date.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      h5 {
        color: #fff !important;
      }
    }
    & > div {
      &:last-of-type {
        margin-top: -20rem;
      }
    }
    // &__body {
    //   height: 50%;
    //   background: #ffffff;
    //   box-shadow: 0 -7px 26px 0 rgba(3, 65, 255, 0.66);
    //   border-radius: 0 0 166px 166px;
    //   color: #000;
    // }
    .overlay {
      opacity: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.2s ease-in-out;
      background-color: #00000070;
      border-radius: 150px;
      button,
      a {
        // height: 50%;
        height: max-content;
        width: 80%;
        // display: block;
        opacity: 1;
        margin: auto;
        transition: all 0.2s ease-in-out;
        background-color: #fff;
        color: #000;
        text-transform: capitalize;
        border: 0;
        padding: 0.7rem 1.2rem;
        font-size: 1.1rem;
        font-weight: bolder;
        border-radius: 10px;
        text-decoration: none;
        @include breakpoint($md) {
          width: max-content;
        }
      }
    }
    img {
      margin: 0 auto 1rem auto;
      display: block;
    }
    .test {
      background-color: #000;
      color: #fff;
    }
    &__body {
      height: 55%;
      background: #ffffff;
      box-shadow: 0 -7px 26px 0 rgba(3, 65, 255, 0.66);
      border-radius: 0 0 166px 166px;
      color: #000;
      h6 {
        transition: all 0.2s ease-in-out;
        padding: 1rem 1rem 0;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #2d506d;
        @include breakpoint($md) {
          font-size: 1.2rem;
        }
      }
      p {
        transition: all 0.2s ease-in-out;
        padding: 0.3rem 1rem 0;
        opacity: 0.7;
        font-size: 1.3rem;
        line-height: 1.3;
        @include breakpoint($md) {
          font-size: 1rem;
        }
      }

      &__footer {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        padding: 0.5rem 0;
        p {
          opacity: 1;
          font-weight: bolder;
          display: flex;
          align-items: center;
          transition: all 0.2s ease-in-out;
          color: $main-blue;
          &:not(:last-child) {
            border-right: 1px solid #000;
          }
          img {
            height: 15px;
            margin-bottom: 0;
            margin-right: 5px;
          }
        }
      }
    }
    h5 {
      font-size: 1.5rem;
      text-transform: uppercase;
      color: #000;
      // opacity: 0;
    }
  }
}
.q-a {
  // border: 1px solid #c6c4c4;
  background-color: #ededed;
  border-radius: 20px;
  padding: 1rem 0.5rem;
  @include breakpoint($md) {
    padding: 2rem 0;
  }
  &__container {
    background-color: #fff;
    border-radius: 20px;
    padding: 3rem 1rem;
    h3 {
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .accordion {
      &-button {
        @include breakpoint($md) {
          font-size: 1.2rem;
        }
        &:not(.collapsed) {
          color: #fff;
          background-color: #2d506d;
          &::after {
            background-image: url("../../assets/images/download.svg");
            transform: rotate(-180deg);
          }
        }
      }
      &-body {
        background-color: #efefefa7;
      }
    }
  }
}
.newsletter__banner {
  border-radius: 40px;
  overflow: hidden;
  height: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  @include breakpoint($md) {
    height: 400px;
  }
  &__content {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53),
        hsla(0, 0%, 46%, 0.223)
      ),
      url("../../assets/images/newsletter.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    // padding: 0 0 0 2rem;
    @include breakpoint($md) {
      background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.53),
          hsla(0, 0%, 98.8%, 0.274)
        ),
        url("../../assets/images/newsletter.jpg");
      padding: 0 4rem;
      // padding: 0 0 0 4rem;
    }
    h5,
    p {
      color: #fff;
    }

    h5 {
      font-size: 2rem;
      text-transform: uppercase;
      text-align: center;
      @include breakpoint($md) {
        font-size: 3rem;
        text-align: left;
      }
    }
    p {
      text-align: center;
      // width: 85%;
      @include breakpoint($md) {
        text-align: left;
        font-size: 1.1rem;
        width: 100%;
      }
    }
  }
}
.gallery {
  overflow: hidden;
  h1 {
    font-size: 5rem;
    font-family: $main-font;
  }
  h2 {
    font-size: 2rem;
    font-family: $main-font;
  }

  img {
    width: 100%;
    height: auto;
    background: #f0f0f0;
  }

  ul {
    padding-left: 1rem;
    list-style: none;
  }

  li {
    flex-shrink: 0;
    width: clamp(500px, 30vw, 800px);
    padding-right: 1rem;
  }

  header {
    height: 100vh;
  }
  footer {
    height: 50vh;
  }

  :-webkit-any-link {
    color: #4e9815;
  }

  :-moz-any-link {
    color: #4e9815;
  }

  :any-link {
    color: #4e9815;
  }

  .df {
    display: flex;
  }
  .aic {
    align-items: center;
  }
  .jcc {
    justify-content: center;
  }

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    color: white;
  }

  .demo-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    .text {
      font-family: $main-font;
    }
  }

  .wrapper {
    display: flex;
  }

  .demo-gallery:not(.last) {
    padding-bottom: 1rem;
  }

  .demo-text .text {
    font-size: clamp(8rem, 15vw, 16rem);
    // line-height: 1;
    font-weight: 900;
    color: #2d506d;
  }
}

.all-services-modal {
  .modal-dialog {
    @include breakpoint($md) {
    }
    @include breakpoint($lg) {
      max-width: 70% !important;
    }
    .modal-body {
      padding: 1rem !important;
    }
  }
  &__content {
    display: grid;
    // grid-template-columns: repeat(1, 1fr);
    // gap: 0.8rem;
    @include breakpoint($md) {
      padding: 1rem;
    }
    @include breakpoint($lg) {
      // grid-template-columns: repeat(3, 1fr);
      // gap: 0.5rem;
    }
    button {
      border: 0;
      padding: 0.8rem 1rem;
      font-style: italic;
      background-color: #f1f0ed;
      color: $main-blue;

      font-size: 1.5rem;
      @include breakpoint($md) {
      }
    }
  }
  &__service {
    background-color: #f1f0ed;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1rem 0.8rem 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    border-radius: 22px;
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
    &:hover {
      cursor: pointer;
    }
    @include breakpoint($lg) {
      grid-template-columns: 20% 55% 1fr;
      padding: 0.6rem 0.95rem 0.6rem 0.6rem;
      transition: all 0.2s ease-in-out;
      &:hover {
        cursor: pointer;
        // font-style: italic;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        box-shadow: 0 2px 1px 2px #2d506d42;
        img {
          // transform: translateX(55%);
        }
        h5 {
        }
      }
    }
    @include breakpoint($lg) {
      padding: 0.8rem 0.95rem 0.8rem 0.8rem;
    }
    img {
      transition: all 0.2s ease-in-out;
      height: 10px;
    }
    h5 {
    }
    .service__info {
      p {
        font-size: 0.9rem;
        text-align: justify;
        text-transform: none;
        span {
          // font-size: 0.5rem;
        }
      }
      &__media {
        height: 150px;
        width: 150px;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 11px;
        img {
          height: 100px;
          display: block;
          margin: 0 auto;
        }
      }
      &__duration {
        opacity: 0.5;
        img {
          height: 20px;
        }
      }
    }
  }
}
