@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.modal {
  &-dialog {
    @include breakpoint($lg) {
      max-width: 90% !important;
    }
  }
  &-body {
    justify-content: stretch !important;
  }
  .circuits__data-payment {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 1rem;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 90%;
    margin-bottom: 1rem;
    @include breakpoint($lg) {
      grid-template-columns: 60% 1fr;
    }
    & > div {
      &:first-of-type {
        border: 1px solid #a3a3a3;
        border-radius: 20px;
        padding: 1rem;
      }
    }
    form {
      margin-top: 1.5rem;
      label {
        font-weight: bolder;
        text-transform: uppercase;
        font-size: 0.8rem;
        opacity: 0.8;
      }
      input {
        padding: 0.6rem 0.75rem;
      }
      button {
        display: block;
        width: 100%;
        border: 0;
        color: #000;
        font-family: $main-font;
        padding: 0.8rem 0;
        &:first-of-type {
          margin: 0.6rem 0;
          font-weight: bolder;
          background-color: #2d506d;
          color: #fff;
          .spinner-border {
            height: 1rem;
            width: 1rem;
          }
          &:disabled {
            background-color: #93a3b1;
          }
        }
      }
    }
    .selected-service {
      background-color: #e3e3e3;
      // background-color: #edeee9;
      border-radius: 11px;
      padding: 1rem;
      @include breakpoint($lg) {
        padding: 1rem;
        height: min-content;
      }
      h6 {
        font-size: 1.2rem;
        @include breakpoint($lg) {
          font-size: 1rem;
        }
      }
      &__date__header {
        span {
          float: right;
          font-size: 1.6rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
      &__circuit-content {
      }
      & > div {
        background-color: #fff;
        border-radius: 11px;
        border: 1.5px solid #000;
        padding: 0.5rem 0.7rem;
        // height: 100%;
        @include breakpoint($lg) {
          padding: 0.7rem 0.7rem;
        }
        &:not(:first-of-type) {
          margin-top: 0.8rem;
        }
        h6 {
          @include breakpoint($lg) {
            font-size: 1.2rem;
          }
        }
        p {
          display: flex;
          align-items: center;
          &:first-of-type {
            @include breakpoint($lg) {
              font-size: 1.2rem;
            }
          }
          img {
            height: 15px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
