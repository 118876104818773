@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.footer {
  background-color: #f0f0f0;
  margin-top: 1rem;
  &__logo {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      margin: 0 auto;
      height: 100px;
      @include breakpoint($md) {
        height: 200px;
      }
    }
  }

  &__content {
    display: flex;
    // border-top: 1.5px solid #787878;
    background-color: #fff;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    flex-direction: column;
    @include breakpoint($md) {
      flex-direction: row;
    }
  }
  &__newsletter,
  &__info,
  &__content__logo {
    flex-grow: 1;
    flex-shrink: 1;
    @include breakpoint($md) {
      padding: 3rem 2rem;
    }
  }
  &__newsletter {
    // border-left: 10px solid #787878;
    flex-basis: 45%;
    a {
      color: #000;
    }
  }
  &__info {
    border-left: 1.5px solid #979797;
    flex-basis: 65%;

    h6 {
      color: #67645e;
      text-transform: uppercase;
      font-size: 0.8rem;
      letter-spacing: 1.1px;
      margin-bottom: 1rem;
    }
    ul {
      padding-left: 0;
      li {
        list-style: none;
        display: flex;
        // align-items: center;
        margin-bottom: 0.8rem;
        // display: flex;
        flex-direction: column;
        a {
          color: #000;
          text-decoration: none;
        }
        img {
          height: 15px;
          margin-right: 0.5rem;
        }
        span {
          // display: block;
          font-weight: bolder;
          font-style: italic;
        }
      }
    }
  }
  &__content__logo {
    flex-basis: 35%;
    @include breakpoint($sm-only) {
      padding: 1.5rem 0;
    }
    img {
      display: block;
      margin: 0 auto;
      height: 200px;
      @include breakpoint($sm-only) {
        height: 100px;
      }
    }
  }
}

.wa-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  img {
    height: 32px;
  }
}
