@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.modal-dialog {
  @include breakpoint($lg) {
    max-width: 90% !important;
  }
  // height: 90vh !important;
  // min-height: 70vh !important;
  .services__data {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 80%;
  }
  .modal-header {
    background-color: #2d506d;
    color: #fff;
    font-family: $main-font;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    button {
      &.btn-close {
        background-image: url("../../assets/images/close-icon.svg") !important;
      }
    }
  }
  .modal-title {
    font-size: 1.2rem !important;
  }
  .modal-content {
    min-height: 85vh !important;
    // border-radius: 25px;
  }
  .modal-body {
    // &:nth-child(even) {
    //   F3F4F5
    // }
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include breakpoint($lg) {
    }
  }
  .StepperContainer-0-2-1 {
    padding: 1rem 0;
    @include breakpoint($lg) {
      padding: 24px;
    }
    .StepContainer-0-2-2 {
      padding-left: 0;
      padding-right: 0;
      @include breakpoint($lg) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .LabelContainer-0-2-10 {
      font-size: 0.55rem;
      @include breakpoint($md) {
        font-size: 0.875rem;
      }
      span {
        margin-top: 0.5rem;
        @include breakpoint($lg) {
          margin-top: 1rem;
        }
      }
    }
  }
  .date-selection,
  .hour-selection,
  .staff-selection,
  .confirmation {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 90%;
    @include breakpoint($lg) {
      padding: 0 0 0 1.5rem;
      grid-template-rows: none;
      grid-template-columns: (70% 1fr);
    }
  }
  .date-selection,
  .hour-selection,
  .staff-selection,
  .confirmation {
    // height: 70%;
    height: 50vh;
    .selected-service {
      background-color: #e3e3e3;
      // background-color: #edeee9;
      border-radius: 11px;
      padding: 1rem;
      height: min-content;
      @include breakpoint($lg) {
        padding: 1rem;
        height: 100%;
      }
      h6 {
        font-size: 1.2rem;
        @include breakpoint($lg) {
          font-size: 1rem;
        }
      }
      & > div {
        background-color: #fff;
        border-radius: 11px;
        border: 1.5px solid #000;
        padding: 0.5rem 0.7rem;
        @include breakpoint($lg) {
          padding: 0.7rem 0.7rem;
        }
        &:not(:first-of-type) {
          margin-top: 0.8rem;
        }
        p {
          display: flex;
          align-items: center;
          img {
            height: 15px;
            margin-right: 5px;
          }
        }
      }
      &__date,
      &__staff,
      &__hour {
        &__header {
          span {
            float: right;
            font-size: 1.5rem;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .calendar {
    @include breakpoint($lg) {
      padding: 0 7rem;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      h2 {
        font-size: 1.1rem;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        @include breakpoint($md) {
        }
      }
      button {
        background-color: #e5e5e5;
        border: none;
        font-size: 1.3rem;
        font-family: $main-font;
        font-weight: bolder;
        height: 30px;
        width: 30px;
        margin-left: 10px;
        margin-right: 10px;
        color: #000 !important;
      }
    }

    &-body {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-days {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      width: 100%;
      text-align: center;
      margin: 10px 0;
      font-weight: bolder;
      font-size: 0.85rem;
      @include breakpoint($md) {
        font-size: 1rem;
      }
      .calendar-day {
        font-size: 0.9rem;
        text-align: center;
        color: #000 !important;
        span {
          color: #000 !important;
        }
      }
    }

    &-dates {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 1rem;
      width: 100%;

      .calendar-day.padding {
        background-color: transparent;
      }

      button.unavailable {
        background-color: #f8d7da;
        color: #721c24;
        border-color: #f5c6cb;
        cursor: not-allowed;
      }

      button:disabled {
        cursor: not-allowed;
      }

      button {
        background-color: rgb(235, 235, 235);
        border: none;
        font-family: $main-font;
        display: block;
        margin: 0 auto;
        height: 45px;
        width: 45px;
        border-radius: 11px;
        color: #000 !important;
        span {
          margin-left: -3px;
          color: #000 !important;
        }
        @include breakpoint($md) {
          height: 40px;
          width: 40px;
          span {
            margin-left: 0;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #2d506d;
          span {
            color: #fff !important;
          }
        }
      }
    }
  }

  .hour-selection {
    &__schedule {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 40px;
      gap: 0.5rem;
      padding: 0 1rem;

      @include breakpoint($lg) {
        grid-template-columns: repeat(5, 1fr);
        padding: 0;
      }

      button {
        background-color: #ececec;
        // background-color: #d6ffd1;
        border: none;
        font-family: $main-font;
        // margin-top: 10px;
        // margin-right: 10px;
        height: 40px;
        width: 100%;
        border-radius: 11px;
        &:hover {
          cursor: pointer;
          background-color: #2d506d;
          color: #fff;
          // background-color: darken(#d6ffd1, 5%);
        }
        span {
          font-size: 0.8rem;
          margin-left: 4px;
          opacity: 0.5;
        }
      }
    }
  }
  .confirmation {
    & > div {
      border: 1px solid #c5c5c5;
      border-radius: 11px;
      padding: 1rem;
      h5 {
        font-size: 1.1rem;
        margin-bottom: 0.6rem;
      }
      form {
        label {
          font-weight: bolder;
          text-transform: uppercase;
          font-size: 0.8rem;
          opacity: 0.8;
        }
        input {
          padding: 0.6rem 0.75rem;
        }
        button {
          display: block;
          width: 100%;
          border: 0;
          color: #000;
          font-family: $main-font;
          padding: 0.8rem 0;
          &:first-of-type {
            margin: 0.6rem 0;
            font-weight: bolder;
            background-color: #2d506d;
            color: #fff;
            .spinner-border {
              height: 1rem;
              width: 1rem;
            }
            &:disabled {
              background-color: #93a3b1;
            }
          }
        }
      }
    }
    .addons {
      background-color: #e3e3e3;
      border-radius: 15px;
      padding: 1rem;
      margin-bottom: 1rem;
      &__cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        margin-top: 10px;
        @include breakpoint($md) {
          gap: 1rem;
          grid-template-columns: repeat(4, 1fr);
        }
        button {
          font-size: 0.9rem;
          background-color: #fff;
          border-radius: 10px;
          border: 2.5px solid #000;
          transition: all 0.3s ease-in-out;
          padding: 0.5rem 0;
          @include breakpoint($md) {
            padding: 0;
            &:hover {
              background-color: $main-blue;
              color: #fff;
              font-weight: bolder;
              transform: translateY(-5px);
            }
          }
        }
      }
    }
  }
  .step-done .circle {
    background: #236ee8;
    color: #eee;
  }

  .step-inactive .circle {
    background: #bbb;
    color: #000;
  }

  .step-active .label {
    color: #000;
  }
  .step {
    &.active {
      background: #236ee8;
      color: #eee;
    }
  }
  #RFS-StepButton {
    font-family: $main-font;
    &.active {
      background: #2d506d;
      color: #eee;
    }
    &.completed {
      background: #e3e3e3;
      color: #eee;
    }
  }
  #RFS-LabelContainer {
    font-family: $main-font;
  }
}
.all-products {
  background-color: #f3f4f5;
  // border-radius: 25px;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 90%;
  display: flex;
  flex-direction: column;
  @include breakpoint($lg) {
  }
  h2 {
    font-size: 1.2rem;
  }
  .accordion {
    &-item {
      // border: 1px solid #000;
      background-color: #f3f4f5;
      border-top: 0 !important;
      border-bottom: 0 !important;
      &:not(:last-child) {
        // border-bottom: #ebe9e9;
        border-bottom: 0;
      }
    }
    &-header {
      button {
        background-color: #f3f4f5;
        // background-color: #ebe9e9;
        font-family: $main-font;
        // font-weight: bolder;
        font-size: 1.6rem;
        padding: 1.5rem 1.2rem;
      }
    }
    &-body {
      padding: 1rem 0.8rem;
      @include breakpoint($lg) {
        padding: 1rem 1.2rem;
      }
    }
  }
  .service {
    // display: flex;
    // flex-direction: row;
    margin-bottom: 1rem;

    input {
      display: none;

      &:checked + label {
        height: auto;
        // width: 135px;
        background-color: #613f75;
        border: 1px solid #fff;
        color: #000;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        background: #f5f5f5;
        border: 1px solid #2d506d;
        border-radius: 11px;
        box-shadow: 0 2px 1px 2px #2d506d;
      }
    }
    label {
      // background: #fff;
      background: #f3f4f5;
      // border: 1px solid #bdbdbd;
      border-bottom: 1px solid #979797;
      border-radius: 0;
      // border-radius: 6px;
      // display: inline-flex;
      height: auto;
      justify-content: space-between;
      // padding-left: 1rem;
      // padding-right: 1rem;
      padding: 1rem 0.8rem 2rem 0.8rem;
      transition: all 0.1s ease-in-out;
      width: 100%;
      // grid-template-columns: 15% 65% 10%;
      display: flex;
      flex-direction: column;
      @include breakpoint($lg) {
        padding: 1rem 1rem 2rem 1rem;
        align-items: center;
        display: grid;
        grid-template-columns: 15% 65% 10%;
      }
      &:hover {
        cursor: pointer;
      }
    }
    // &__info,
    // span {
    //   flex-grow: 1;
    //   flex-shrink: 1;
    // }
    &__info {
      // flex-basis: 83%;
      // border-right: 1px solid #bdbdbd;
      // padding-right: 0.3rem;
      @include breakpoint($sm-only) {
        margin: 1rem 0;
      }
      &__media {
        height: 150px;
        width: 150px;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 11px;
        img {
          height: 100px;
          display: block;
          margin: 0 auto;
        }
      }
      &__duration {
        opacity: 0.5;
        font-size: 0.8rem !important;
      }
      h5 {
        color: $final-blue;
        @include breakpoint($lg) {
          font-size: 1.4rem;
          text-transform: uppercase;
          margin-bottom: 0.6rem;
        }
        span {
          font-size: 1rem;
          opacity: 0.4;
          margin-left: 10px;
        }
      }
      p {
        font-size: 0.85rem;
        opacity: 0.7;
        text-align: justify;
        max-width: 95%;
      }
    }
    span {
      // flex-basis: 17%;
      // text-align: center;
      display: inline-flex;
      align-items: center;
      margin-bottom: 0.2rem;
      font-size: 0.95rem;
      line-height: 1.3;
      @include breakpoint($md) {
        font-size: 1rem;
        margin-bottom: 0.8rem;
      }
      // &:last-child {
      //   opacity: 0.6;
      // }
      img {
        height: 17px;
        margin-right: 7px;
      }
    }
  }
  .stepper-next {
    background-color: #000;
    border: 0;
    color: #fff;
    font-family: $main-font;
    font-weight: bolder;
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem 0;
    margin-top: 0.6rem;
    &:disabled {
      background-color: #a9a9a9;
      cursor: not-allowed;
    }
  }
}
.staff-selection {
  & > div {
    padding: 0 1rem;
  }
  button {
    background-color: rgb(235, 235, 235);
    // background-color: #eefded;
    border: none;
    font-family: $main-font;
    margin-top: 10px;
    margin-right: 10px;
    height: 40px;
    width: max-content;
    padding: 0 1rem;
    border-radius: 11px;
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      // background-color: darken(rgb(235, 235, 235), 5%);
      background-color: #2d506d;
      color: #fff;
      // background-color: darken(#eefded, 5%);
    }
  }
}
.error {
  color: #000;
  border-radius: 11px;
  padding: 1rem 0;
  // color: #f21d1d;
  // font-family: $main-font;
  // background-color: #f5a9a9;
}

.original-workflow {
  display: block;
  margin: 1rem auto;
  background-color: $final-blue;
  border-radius: 15px;
  color: #fff;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
#card-element {
  // background: #f2f2f2;
  // border-radius: 3px;
  // border: none;
  // padding: 1rem;
  // margin-top: 1rem;
  & > div {
    background: white;
    border: 1px solid #dad8d8;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-top: 0.5rem;
    input {
      font-family: $main-font !important;
    }
  }
}
