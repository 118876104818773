@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.custom__nav {
  position: relative;
  z-index: 10;
  top: 1rem;
  @include breakpoint($sm-only) {
    top: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #navbarBtn {
    display: none;
  }
  .nav-link {
    color: #fff !important;
    font-family: $main-font;
    letter-spacing: 1px;
  }
  // .active {
  //   color: #fff;
  // }
  .navbar-brand {
    img {
      height: 35px;
      margin-left: 0.6rem;
    }
  }
  &__l {
    display: flex;
    .nav-link {
      @include breakpoint($md) {
        margin-right: 1.5rem;
      }
    }
  }
  &__c {
    display: none;
    @include breakpoint($md) {
      position: relative;
      right: 2.5rem;
      // transform: translateX(-7.5rem);
      transform: translateX(-8rem);
      display: block;
    }
    @include breakpoint($lg) {
      right: 4rem;
    }
  }
  &__r {
    .nav-link {
      position: relative;
      right: 12rem;
      // @include breakpoint($md) {
      //   border: 2px solid #fff;
      //   padding: 8px 2rem !important;
      //   border-radius: 15px;
      //   &:hover {
      //     background-color: #fff;
      //     color: #000 !important;
      //   }
      // }
    }
  }
}
