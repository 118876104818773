@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.custom__nav__top {
  background-color: $kaki;
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
  margin: 0.6rem 0.6rem 0 0.6rem;
  padding: 0.45rem 0;
  @include breakpoint($md) {
    margin: 0.6rem 1.5rem 1.5rem 1.5rem;
  }
  h6 {
    font-size: 0.8rem;
    @include breakpoint($md) {
      font-size: 0.8rem;
      letter-spacing: 1.2px;
    }
  }
}
