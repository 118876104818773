@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.stripeError {
  background-color: #facece;
  color: #f55c5c;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  text-align: center;
  margin-top: 0.5rem;
}
.security {
  display: flex;
  margin-top: 10px;
  img {
    height: 20px;
    margin-right: 5px;
  }
  p {
    font-weight: bolder;
  }
}
.secureInformation-collapse {
  padding: 1rem 0.5rem;
  background-color: #efefef;
  border-radius: 10px;
  margin-top: 10px;
  text-align: justify;
  font-size: 0.9rem;
  span {
    font-weight: bolder;
  }
  a {
    color: #000;
  }
}
.payment-type__legend {
  font-size: 0.9rem;
  font-weight: bolder;
  margin: 10px 0 0 0;
  &:not(:first-of-type) {
    margin: 1rem 0;
  }
}
.selected-products__payment {
  background-color: #f7f7f7;
  padding: 0.5rem;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  h5 {
    font-family: $secondary-font;
    font-size: 1rem !important;
    color: #2d9634;
  }
  h6 {
    padding: 0.5rem 0;
    text-align: right;
    font-family: $secondary-font;
    font-weight: bolder;
  }
}
