// Color fonts
$light-blue: #0053b9;
$main-blue: #0f2f85;
$dark-blue: #0d193a;
$main-blue: #2d506d;
$light-green: #acea00;
$opacity-green: #d2fa93;
$dark-green: #82bd02;
$light-grey: #eae5db;
$body-background: #fef9f1;
$dark: #231f20;
$final-blue: #2d506d;

$kaki: #f1f0ed;
$light-kaki: #f8f8f6;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Rubik:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "Hatton";
  src: url("../../fonts/PP-Hatton-Medium-500.otf");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

// Font variables
$main-font: "Hatton", sans-serif;
$secondary-font: "Montserrat", sans-serif;
